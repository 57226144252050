
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules';
import { IAuthUser } from '@/types/user.types';
import webConfig from '@/config';
import { UserService } from '@/services';

@Component
export default class AddCards extends Vue {
  olivePublicKey = webConfig.api.olivePublicKey;
  oliveCardSdk = webConfig.api.oliveCardSdk;
  currentEnv = webConfig.app.envName;
  get user(): IAuthUser | null {
    return UserModule.user;
  }
  async mounted(): Promise<void> {
    this.loadCardScript();
    window.creditCardSetup = this;
  }

  loadCardScript(): void {
    let scriptEl = document.getElementById('olive-link-card-form');
    scriptEl?.setAttribute('data-public-key', this.olivePublicKey);

    const scriptSdk = document.createElement('script');
    scriptSdk.src = this.oliveCardSdk;
    scriptSdk.id = 'olive-link-card-form';
    scriptSdk.type = 'application/javascript';

    const attributes = {
      'data-public-key': this.olivePublicKey,
      'data-container-div': 'olive-sdk-container',
      'data-auto-open': 'true',
      'data-include-cvv': 'true',
      'data-include-address': 'false',
      'data-include-header': 'false'
    };

    (Object.keys(attributes) as Array<keyof typeof attributes>).forEach(
      (key) => {
        if (attributes[key] != null) {
          return scriptSdk.setAttribute(key, attributes[key]);
        }
      }
    );

    // Set data-environment
    if (this.currentEnv != 'production')
      scriptSdk.setAttribute('data-environment', 'sandbox');

    let scriptCallback = document.createElement('script');
    scriptCallback.innerHTML = `function callback(paymentMethodData, error, successFlag) {
     
     window.creditCardSetup.handleEnrollCardResponse(paymentMethodData, error, successFlag);
      
    }`;

    let divScript = document.getElementById('script-sdk');
    divScript?.appendChild(scriptCallback);
    divScript?.appendChild(scriptSdk);
  }

  async handleEnrollCardResponse(
    paymentMethodData: any,
    error: any,
    successFlag: any
  ) {
    if (successFlag) {
      try {
        // Create card in the back-end (including Olive)
        await UserService.addPaymentMethod(paymentMethodData);
        let user = await UserService.fetchUser();
        UserModule.setUser(user);
        this.$router.replace('/completed-setup');
      } catch (err: any) {
        const errorMsg = this.$getError(err);
        this.$notifyError(errorMsg);
      }
    } else {
      this.$notifyError(error);
    }
  }
}
